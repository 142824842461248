import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.querySelector("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));

const spaces = rootElement?.dataset?.spaces
  ? JSON.parse(rootElement.dataset.spaces)
  : [];

root.render(
  <React.StrictMode>
    <App spaces={spaces} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
