import { Map, GoogleApiWrapper, Marker } from "google-maps-react18";
import styles from "./Location.module.scss";

import locationImage from "../../assets/images/location.png";
import { Link } from "react-router-dom";
// import mapImage from "../../assets/images/map.png";
// import MapComponent from "./map";
// import Marker from "./marker";

const Location = (props) => {
  return (
    <div className={styles.location}>
      <div className={`${styles.headerWrpr}`}>
        <div className={`contentWrpr`}>
          <div className={`df aic p-l-10 p-r-10 ${styles.headerInnerWrpr}`}>
            <p className={`df aic ffmb fs31 ttuc`}>
              <img className={`m-r-20`} src={locationImage} alt="" />
              location
            </p>
            <p className={`ffmm fs28 mla ${styles.sub}`}>
              22-27 Wood Road, Round Lake, NY 12151
            </p>
          </div>
        </div>
      </div>
      <div
        className={styles.mapWrpr}
        style={{ height: "684px", width: "100%", marginTop: "-100px" }}
      >
        <div
          className={styles.cContentWrpr}
          style={{
            height: "580px",
            width: "100%",
            padding: "0px 40px",
            maxWidth: "1518px",
            marginTop: "-126px",
            margin: "-130px auto 0px",
          }}
        >
          <div
            className={`contentWrpr`}
            style={{
              padding: "initial",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            {/* <img src={mapImage} alt="map image" /> */}
            {/* <Wrapper apiKey={"AIzaSyCIAjRoSK9lAZ5lfTVe1kYgJGEggap2-Ow"}>
            <MapComponent>
              <Marker position={{ lat: 42.90988, lng: -73.78508 }} />
            </MapComponent>
          </Wrapper> */}
            <Map
              google={props.google}
              zoom={14}
              initialCenter={{
                lat: 42.90988,
                lng: -73.78508,
              }}
            >
              <Marker
                key="marker_1"
                position={{
                  lat: 42.90988,
                  lng: -73.78508,
                }}
              />
            </Map>
          </div>
        </div>

        <Link to="/contact" className={`ffmm fs14 ${styles.button}`}>
          Contact us
        </Link>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCIAjRoSK9lAZ5lfTVe1kYgJGEggap2-Ow",
})(Location);
// export default Location;
