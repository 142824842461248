import { Link } from "react-router-dom";
import styles from "./Landing.module.scss";

const Landing = (props) => {
  return (
    <div id="home" className={`p-t-20 ${styles.landing}`}>
      {/* Tech Valley Flex Park */}

      <div className={`df aic contentWrpr`}>
        <div className={`${styles.content}`}>
          <h1 className={`ffmsb fs80`}>Tech Valley Flex Park</h1>
          <h4 className={`m-t-20 ffmm fs24`}>
            Mixed-use spacious industrial warehouse space for rent{" "}
          </h4>

          <div className={`df m-t-70 ${styles.buttonsWrpr}`}>
            <Link to="/contact" className={`ffmb fs14`}>
              Rent A Space
            </Link>
            <Link
              to="/contact"
              className={`ffmb fs14 m-l-20 ${styles.contact}`}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
