import styles from "./Header.module.scss";

import { NavHashLink } from "@xzar90/react-router-hash-link";
import logo from "../../assets/images/tech-valley-logo.png";

import darkLogo from "../../assets/images/dark-logo.png";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = ({ theme = "dark" }) => {
  const { pathname } = useLocation();

  const [localTheme, setLocalTheme] = useState(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (pathname !== "/") {
      setLocalTheme("dark");
    } else {
      setLocalTheme(undefined);
    }
  }, [pathname]);
  return (
    <div
      className={`ffmsb ${styles.header} ${
        localTheme === "dark" || theme === "dark" ? styles.dark : styles.light
      }`}
    >
      <div className={`df aic contentWrpr`}>
        <Link to="/" onClick={() => setOpen(false)}>
          <img
            src={localTheme === "dark" || theme === "dark" ? darkLogo : logo}
            alt="logo"
          />
        </Link>

        <div
          onClick={() => setOpen(!open)}
          className={`mla ${styles.mobileOpener} ${open ? styles.open : ""}`}
        ></div>

        <div
          className={`df aic mla ${styles.navsWrpr} ${open ? styles.open : ""}`}
        >
          <NavHashLink
            onClick={() => setOpen(false)}
            to="/#home"
            className={(props) => {
              return `${
                props.isActive
                  ? `ffmsb fs18 ${styles.isActive} ${styles.nav}`
                  : `ffmsb fs18 ${styles.nav}`
              }`;
            }}
            style={(props) => {
              return props.isActive ? { fontWeight: "bold" } : {};
            }}
          >
            Home
          </NavHashLink>
          <NavHashLink
            onClick={() => setOpen(false)}
            to="/#about"
            className={(props) => {
              return `${
                props.isActive ? `${styles.isActive} ${styles.nav}` : styles.nav
              }`;
            }}
            style={(props) => {
              return props.isActive ? { fontWeight: "bold" } : {};
            }}
          >
            About
          </NavHashLink>
          <NavHashLink
            onClick={() => setOpen(false)}
            to="/#spaces"
            className={(props) => {
              return `${
                props.isActive ? `${styles.isActive} ${styles.nav}` : styles.nav
              }`;
            }}
            style={(props) => {
              return props.isActive ? { fontWeight: "bold" } : {};
            }}
          >
            Available Spaces
          </NavHashLink>

          <NavHashLink
            onClick={() => setOpen(false)}
            to="/#features"
            className={(props) => {
              return `${
                props.isActive ? `${styles.isActive} ${styles.nav}` : styles.nav
              }`;
            }}
            style={(props) => {
              return props.isActive ? { fontWeight: "bold" } : {};
            }}
          >
            Property Features
          </NavHashLink>
          <a
            onClick={() => setOpen(false)}
            href="https://propup.twa.rentmanager.com"
            className={styles.nav}
            target="_blank"
            rel="noreferrer"
          >
            Tenant Login
          </a>
          <Link
            onClick={() => setOpen(false)}
            to="/contact"
            className={`df acc fs18 ffmsb ${styles.contactBtn}`}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
