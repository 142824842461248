import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Header from "./components/header/Header";
import Landing from "./components/landing/Landing";
import About from "./components/about/About";
import Spaces from "./components/spaces/Spaces";

import Features from "./components/features/Features";

import styles from "./App.module.scss";
import Location from "./components/location/Location";
import Footer from "./components/foooter/Footer";
import { useLayoutEffect, useRef, useState } from "react";
import Contact from "./components/contact/Contact";
import Space from "./components/spaces/space/Space";
import SiteMap from "./components/site-map/SiteMap";

const Wrapper = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.getElementsByClassName("App")[0].scrollTo(0, 0);
  }, [pathname]);
  return children;
};

function App({ spaces }) {
  const [scrollAmount, setScrollAmount] = useState(0);

  let appRef = useRef(null);

  const scrolling = () => setScrollAmount(appRef?.current.scrollTop);

  // useEffect(()=> {},[pathname])

  const getPathName = () => {
    return window.location.pathname === "/";
  };

  return (
    <div className="App" ref={appRef} onScroll={scrolling}>
      <BrowserRouter>
        <Wrapper>
          <Header
            theme={getPathName() && scrollAmount < 140 ? "light" : "dark"}
          />
          <div className={styles.pageContent}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Landing />
                    <About />
                    <Features />
                    <SiteMap />
                    <Spaces spaces={spaces} />
                    <Location />
                  </>
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/space/:idx" element={<Space spaces={spaces} />} />
            </Routes>

            <Footer />
          </div>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
