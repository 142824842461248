import { NavHashLink } from "@xzar90/react-router-hash-link";
import logoImg from "../../assets/images/tech-valley-logo.png";

import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={`df contentWrpr ${styles.contentWrpr}`}>
        <div className={styles.logoSec}>
          <img src={logoImg} alt="logo" />
          <h5 className={`ffmb fs22 m-b-15 m-t-30`}>Tech Valley Flex Park</h5>
          <p className={`ffmr fs18 m-b-2`}>23 Wood Road,</p>
          <p className={`ffmr fs18`}>Round Lake, NY 12151</p>
          <p className={`ffmr fs14 m-t-20 m-b-30`}>
            Proudly managed by{" "}
            <a
              href="https://www.propupgroup.com"
              target="_blank"
              rel="noreferrer"
            >
              PropUp Group
            </a>
          </p>
        </div>
        <div className={`p-t-12 ${styles.navSec}`}>
          <h5 className={`ffmm fs18`}>Company</h5>
          {/* <HashL className={`ffmr fs18 m-b-2`}>23 Wood Road,</p> */}
          <NavHashLink to="/#home" className={`ffmr fs14 m-b-2`}>
            Home
          </NavHashLink>
          <NavHashLink to="/#about" className={`ffmr fs14 m-b-2`}>
            About
          </NavHashLink>
          <NavHashLink to="/#spaces" className={`ffmr fs14 m-b-2`}>
            Available Spaces
          </NavHashLink>
          <NavHashLink to="/#features" className={`ffmr fs14 m-b-2`}>
            Property Features
          </NavHashLink>
        </div>
        {/* <div className={`mla ${styles.right}`}>
          <h5 className={`ffmb fs42`}>Subscribe to our newsletter</h5>
          <div className={styles.inputWrpr}>
            <input placeholder="Your Email" className={`ffmr fs14`} />

            <button className={styles.subscribeBtn}></button>
          </div>
        </div> */}
      </div>
      <div className={`p-t-40 p-b-40 df contentWrpr ${styles.infoContentWrpr}`}>
        <p className={`ffmm fs12`} style={{ width: "28%" }}>
          © 2020 Tech Valley Inc.
        </p>
        <p className={`ffmr fs12`} style={{ width: "15%" }}>
          Privacy Policy
        </p>
        <p className={`ffmr fs12`} style={{ width: "15%" }}>
          Terms and Conditions
        </p>
      </div>
    </div>
  );
};

export default Footer;
