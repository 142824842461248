import styles from "./About.module.scss";

import img1 from "../../assets/images/about-image-1.png";

import arrow from "../../assets/images/arrow.png";

const About = (props) => {
  return (
    <div className={styles.about}>
      <div id="about" className={`anchorPoint`}></div>
      <div className={`df aic contentWrpr ${styles.contentWrpr}`}>
        <div className={`${styles.imageSection}`}>
          <div className={styles.imgWrpr}>
            <img src={img1} alt="" />
          </div>
          <div className={`df aic ${styles.imgNavigationWrpr}`}>
            <div className={`df acc m-r-20 ${styles.nav} ${styles.back}`}>
              <img src={arrow} alt="" />
            </div>
            <div className={`df acc ${styles.nav} ${styles.next}`}>
              <img src={arrow} alt="" />
            </div>
          </div>
        </div>
        <div className={`${styles.description}`}>
          <h5 className={`ffmsb fs19 m-b-50`}>ABOUT</h5>

          <p className={`ffmb fs26 m-b-55 ${styles.subTitle}`}>
            Tech Valley's, spacious Warehouse and office spaces for rent.
          </p>

          <p className={`ffmm fs14`}>
            Tech Valley Flex Park is a mixed-use industrial warehouse and
            business center located in the suburban town of Clifton Park in
            Saratoga County, New York approximately 12 miles north of Albany the
            state capital.
          </p>
          <p className={`ffmm fs14`}>
            Tech Valley Flex Park has a total of 70,900 SF of mixed-use space,
            with some open leasing available.
          </p>
          <button className={`ffmm fs14 m-t-30 ${styles.viewButton}`}>
            View available spaces
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
