import styles from "./Spaces.module.scss";

import loadingDock from "../../assets/images/loading-dock.png";
import ceiling from "../../assets/images/ceiling-height.png";
import { Link } from "react-router-dom";

const Spaces = ({ spaces }) => {
  return (
    <div className={styles.spaces}>
      <div id="spaces" className={`anchorPoint`}></div>
      <h4>
        <div className={`df acc contentWrpr ffmsb fs35 ttuc`}>
          Available spaces
        </div>
      </h4>
      <div className={`contentWrpr`}>
        <div className={styles.spacesWrpr}>
          {spaces
            .filter((elem) => elem.published)
            .map((space, idx) => (
              <Link to={`/space/${idx}`} className={styles.space}>
                <p className={`ffmsb fs22 tac m-b-25`}>{space.address}</p>
                <img
                  className={styles.mainImg}
                  src={space.imageUrl}
                  width="100%"
                  alt=""
                />

                <div className={`df aic ${styles.spaceLine}`}>
                  <div className={`p-r-5 ${styles.lineSec}`}>
                    <p className={`ffmm fs14 m-b-8 ${styles.label}`}>
                      Space Type
                    </p>
                    <p className={`ffmb fs1 ${styles.value}`}>{space.type}</p>
                  </div>
                  <div className={styles.lineSec}>
                    <p className={`ffmm fs14 m-b-8 ${styles.label}`}>
                      Total Space Available
                    </p>
                    <p className={`ffmb fs1 ${styles.value}`}>{space.space}</p>
                  </div>
                </div>

                <div className={`df aic p-b-18 ${styles.spaceLine}`}>
                  <div className={`p-r-5 ${styles.lineSec}`}>
                    <p className={`ffmm fs14 m-b-8 ${styles.label}`}>
                      Available Date
                    </p>
                    <p className={`ffmb fs1 capitalize ${styles.value}`}>
                      {space.availableDate}
                    </p>
                  </div>
                  <div className={styles.lineSec}>
                    <p className={`ffmm fs14 m-b-8 ${styles.label}`}>
                      Suite Number
                    </p>
                    <p className={`ffmb fs1 ${styles.value}`}>
                      {space.suiteNumber}
                    </p>
                    {/*  */}
                  </div>
                </div>
                <div className={`df aic ${styles.featuresWrpr}`}>
                  {space.garageDoor ? (
                    <div className={`df aic ${styles.featureWrpr}`}>
                      <div className={`df aic`}>
                        <img className={`m-r-10`} src={loadingDock} alt="" />
                        <p className={`ffmr fs12`}>Loading Deck</p>
                      </div>
                    </div>
                  ) : undefined}

                  {space.ceiling20 ? (
                    <div className={`df aic ${styles.featureWrpr}`}>
                      <div className={`df aic`}>
                        <img className={`m-r-10`} src={ceiling} alt="" />
                        <p className={`ffmr fs12`}>10 ft. Ceiling</p>
                      </div>
                    </div>
                  ) : undefined}
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Spaces;
