import styles from "./Features.module.scss";

import hwy9 from "../../assets/images/hwy9-img.png";
import i87 from "../../assets/images/i-87-img.png";
import albanyAirport from "../../assets/images/albany-airport-img.png";

const Features = ({ background }) => {
  return (
    // <div className={styles.features}>
    //   F6F9FC!
    <p
      className={`${styles.features}`}
      style={{ backgroundColor: background ? "#fff" : "" }}
    >
      <div id="features" className={`anchorPoint`}></div>
      <div className={`contentWrpr`}>
        <h3 className={`p-t-80 m-b-40 ffmb fs31`}>HIGHLIGHTS</h3>
        <div className={`df ${styles.listWrpr}`}>
          <div className={`${styles.small} ${styles.listSec}`}>
            <div>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                Large grade doors ( 10' x16' )
              </p>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                20 ft. ceiling height
              </p>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                Gas forced hot air
              </p>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                Fire Sprinkler
              </p>
            </div>
          </div>
          <div className={`${styles.listSec} ${styles.directions}`}>
            <div>
              <p className={`df aic ffmb fs18 ${styles.line} ${styles.title}`}>
                Well-situated close to Saratoga Springs, Troy, & Albany
              </p>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                <span className={styles.primary}>Direct access from</span>
                <img src={hwy9} alt="" />
              </p>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                <span className={styles.primary}>1 Mile drive from</span>
                <img src={i87} alt="" />
              </p>
              <p className={`df aic ffmb fs18 ${styles.line}`}>
                <span className={styles.primary}>20 Min drive - 13.5 mi</span>
                <img src={albanyAirport} alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </p>
    // </div>
  );
};

export default Features;
