import { useState } from "react";
import { useParams } from "react-router";
import dockImage from "../../../assets/images/loading-dock.png";
import ceilingHeight from "../../../assets/images/ceiling-height.png";
import styles from "./Space.module.scss";
import Features from "../../features/Features";
import { Link } from "react-router-dom";

const Space = ({ spaces }) => {
  const params = useParams();

  const [thisSpace, setThisSpace] = useState({});

  useState(() => {
    setThisSpace(spaces[params.idx]);
  }, [params.idx]);

  return (
    <div className={`${styles.space}`}>
      <div className="m-b-25 contentWrpr">
        <Link to="/#spaces" className={`df acc ffmm fs14 ${styles.backButton}`}>
          Back
        </Link>
      </div>
      <div className={`df aic contentWrpr ${styles.contentWrpr}`}>
        <div className={styles.leftSec}>
          <img src={thisSpace?.imageUrl} alt="" />
        </div>
        <div className={`df ffc ${styles.rightSec}`}>
          <div className={`ffmr fs11 ${styles.bullet}`}>Available</div>
          <h3 className={`m-t-10 ffmb fs22`}>{thisSpace?.address}</h3>
          <div className={`m-t-30 df ${styles.line}`}>
            <div className={styles.sec}>
              <p className={`ffmm fs14 m-b-5`}>Space Type</p>
              <p className={`ffmb fs14`}>{thisSpace.type}</p>
            </div>
            <div className={styles.sec}>
              <p className={`ffmm fs14 m-b-5`}>Total Space Available</p>
              <p className={`ffmb fs14`}>{thisSpace.space}</p>
            </div>
          </div>
          <div className={`df m-t-30 ${styles.line} ${styles.second}`}>
            <div className={styles.sec}>
              <p className={`ffmm fs14 m-b-5`}>Available Date</p>
              <p className={`ffmb fs14`}>{thisSpace.availableDate}</p>
            </div>
            <div className={styles.sec}>
              <p className={`ffmm fs14 m-b-5`}>Renewal Option</p>
              <p className={`ffmb fs14`}>
                {thisSpace.renewalOption ? "Yes" : "No"}
              </p>
            </div>
          </div>
          <div className={`df aic m-t-35 m-b-35`}>
            {thisSpace.ceiling20 ? (
              <div className={`df aic m-r-25`}>
                <img src={dockImage} alt="" />
                <p className={`ffmr fs12 m-l-10`}>
                  Large garage doors ( 10' x16' )
                </p>
              </div>
            ) : undefined}

            {thisSpace.ceiling20 ? (
              <div className={`df aic`}>
                <img src={ceilingHeight} alt="" />
                <p className={`ffmr fs12 m-l-10`}>20 ft. Ceiling</p>
              </div>
            ) : undefined}
          </div>
          <button className={`ffmm fs14 ${styles.rentButton}`}>
            Rent this space
          </button>
        </div>
      </div>
      <Features background={true} />
    </div>
  );
};

export default Space;
