import styles from "./SiteMap.module.scss";
import siteMap from "../../assets/images/sitemap.png";

import NortheastHVAC from "../../assets/images/logos/northeast-logo.png";
import accel from "../../assets/images/logos/accel-logo.png";
import cd from "../../assets/images/logos/cd-logo.png";
import colonial from "../../assets/images/logos/colonial-fire-logo.png";
import dwc from "../../assets/images/logos/dwc-logo.png";
import empire from "../../assets/images/logos/empire-logo.png";
import eo from "../../assets/images/logos/eo-logo.png";
import korner from "../../assets/images/logos/kornerstone-logo.png";
import martins from "../../assets/images/logos/martins-logo.png";
import onsight from "../../assets/images/logos/onsight-logo.png";
import pj from "../../assets/images/logos/pj-logo.png";
import ws from "../../assets/images/logos/ws-logo.png";
import yericho from "../../assets/images/logos/yericho-logo.png";
import sb from "../../assets/images/logos/sb.png";
import tf from "../../assets/images/logos/tf.png";

// import accel from "../../assets/images/logos";
const SiteMap = () => {
  return (
    <div className={styles.siteMap}>
      <div className={`anchorPoint`}></div>
      <h4>
        <div className={`df acc contentWrpr ffmsb fs35 ttuc`}>Site Map</div>
      </h4>
      <div className={styles.imgSec}>
        <div className={`contentWrpr`}>
          <img className={styles.siteMapImg} src={siteMap} alt="Site Map" />
        </div>
      </div>

      <div className={`${styles.logoSec}`}>
        <div className={`contentWrpr `}>
          <p className={`tac ffmsb ttuc ${styles.logosHeader}`}>
            Our current tenants
          </p>
          <div className={styles.logosWrpr}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://nehvacsolutions.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={NortheastHVAC} alt="NortheastHVAC" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://accelsports.net/"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={accel} alt="accel" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://capitaldistrictsportandfitness.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={cd} alt="cd" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.colonialfire.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={colonial} alt="colonial" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.deathwishcoffee.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={dwc} alt="dwc" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ebplwc.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={empire} alt="empire" />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://pauljohnsonfood.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={pj} alt="pj" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://kornerstoneroofing.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={korner} alt="korner" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://potatorolls.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={martins} alt="martins" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.eonsight.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={onsight} alt="onsight" />
            </a>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.eostechny.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={eo} alt="eo" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.wyrestorm.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={ws} alt="ws" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.yericomfg.com"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={yericho} alt="yericho" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://sbcomputers-office.com/"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={sb} alt="sb" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.tfs-us.com/en"
              className={`df acc ${styles.logoWrpr}`}
            >
              <img src={tf} alt="tf" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMap;
